import { Button } from "@/components/ui/button"
import { CustomLink } from "@/components/ui/link"

export const BannerComponent = ({ href, text }: { href: string; text: string }) => {
  return (
    <Button
      asChild
      variant={"default"}
      size={"lg"}
      className="basic-padding size-full cursor-pointer"
    >
      {/* <CustomLink href={'https://zdrowy-sukces.pl/produkt/dieta-przeciwpasozytnicza-przeciwgrzybicza-low-carb'} rel={"noreferrer noopener"} ariaLabel={'Przejdz do strony produktu'} title={'Dieta przeciwpasożytnicza'} target={'_blank'} className='h-full py-2'>
                <div className="max-w-screen-2xl mx-auto text-wrap">
                    NOWOŚĆ! Sprawdź dietę przeciwpasożytniczą, low carb + suplementacja!
                </div>
            </CustomLink> */}
      <CustomLink
        href={href}
        rel={"noreferrer noopener"}
        ariaLabel={"Przejdz do strony produktu"}
        title={"Dieta przeciwpasożytnicza"}
        target={"_blank"}
        className="h-full py-2"
      >
        <div className="mx-auto max-w-screen-2xl text-wrap">{text}</div>
      </CustomLink>
    </Button>
  )
}
