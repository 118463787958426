import * as z from "zod"

export const CookiesSchema = z.object({
  truly_necessary: z.boolean().default(true).optional(),
  functional: z.boolean().default(true).optional(),
  analytical: z.boolean(),
  advertising: z.boolean(),
  personalizing_content: z.boolean(),
  user_data: z.boolean(),
  personalizing_ads: z.boolean(),
})
