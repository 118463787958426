"use client"

import React from "react"
import Image from "next/image"
import Link from "next/link"
import { cn } from "@/utils/cn"
import { motion } from "framer-motion"
import { LuChevronDown } from "react-icons/lu"

import ShineBorder from "./border-shine"
import { CustomLink } from "./link"

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
}

export const MenuItem = ({
  setActive,
  active,
  item,
  className,
  href,
  children,
}: {
  setActive: (item: string) => void
  active: string | null
  item: string
  href: string
  className?: string
  children?: React.ReactNode
}) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="relative">
      <CustomLink
        href={"/" + href}
        rel={"noreferrer noopener"}
        ariaLabel={`Przejdź do ${item}`}
        title={item}
        target={"_self"}
        className="hover:text-primary-500 cursor-pointer rounded-md transition-colors hover:opacity-90"
      >
        <span className="flex items-center gap-1">
          {item}{" "}
          <LuChevronDown
            className={cn(
              active == item && "rotate-180",
              "font-light transition-transform duration-300"
            )}
          />
        </span>
      </CustomLink>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
        >
          {active === item && (
            <div className="absolute left-1/2 top-[calc(100%_+_1.2rem)] -translate-x-1/2 pt-4">
              <motion.div
                transition={transition}
                layoutId="active"
                className="bg-background/95 supports-[backdrop-filter]:bg-background/90 overflow-hidden rounded-2xl border border-black/[0.2] text-black shadow-xl backdrop-blur backdrop-saturate-150 dark:border-white/[0.2] dark:text-white"
              >
                <motion.div layout className={cn(className, "h-full w-max p-2 lg:p-4")}>
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  )
}

export const MenuItemLink = ({
  setActive,
  item,
  href,
}: {
  setActive: (item: string) => void
  item: string
  href: string
}) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="relative">
      <CustomLink
        href={href}
        rel={"noreferrer noopener"}
        ariaLabel={`Przejdź do ${item}`}
        title={item}
        target={"_self"}
        className="hover:text-primary-500 focus-visible:ring-ring cursor-pointer rounded-md transition-colors hover:opacity-90 focus-visible:outline-none focus-visible:ring-1"
      >
        {item}
      </CustomLink>
    </div>
  )
}

export const Menu = ({
  setActive,
  children,
}: {
  setActive: (item: string | null) => void
  children: React.ReactNode
}) => {
  return (
    <nav
      onMouseLeave={() => setActive(null)}
      className="boder basic-padding shadow-input relative flex w-full max-w-screen-2xl justify-between border-transparent py-4"
    >
      {children}
    </nav>
  )
}
export const ProductItem = ({
  title,
  description,
  href,
  src,
  bluredDataUrl,
  ariaLabel,
  rel = "noopener noreferrer",
  target = "_self",
}: {
  title: string
  description: string
  href: string
  src: string
  bluredDataUrl: string
  ariaLabel: string
  rel?: string
  target?: "_blank" | "_self" | "_parent" | "_top"
}) => {
  return (
    <Link
      href={href}
      aria-label={ariaLabel}
      rel={rel}
      target={target}
      title={title}
      prefetch={false}
      className="text-dark focus-visible:ring-ring group flex space-x-4 focus-visible:outline-none focus-visible:ring-1 dark:text-white"
    >
      <ShineBorder color={["#A07CFE", "#FE8FB5", "#FFBE7B"]}>
        <Image
          src={src}
          width={184}
          height={288}
          alt={title}
          // priority={true}
          loading="lazy"
          placeholder="blur"
          blurDataURL={bluredDataUrl}
          className="h-auto w-24 shrink-0 rounded-md object-contain shadow-2xl"
        />
      </ShineBorder>

      <div>
        <h4 className="group-hover:text-primary-500 mb-1 max-w-[20ch] text-xl font-bold transition-colors">
          {title}
        </h4>
        <p className="max-w-48 text-sm">{description}</p>
      </div>
    </Link>
  )
}

interface HoveredLink {
  href: string
  rel?: string
  ariaLabel: string
  title: string
  target?: "_blank" | "_self" | "_parent" | "_top"
  children?: React.ReactNode
}
export const HoveredLink = ({
  href,
  ariaLabel,
  rel = "noopener noreferrer",
  title,
  target = "_self",
  children,
}: HoveredLink) => {
  return (
    <CustomLink
      href={href}
      ariaLabel={ariaLabel}
      rel={rel}
      title={title}
      target={target}
      className="text-dark hover:text-black dark:text-white"
    >
      {children}
    </CustomLink>
  )
}
