export const MainMenu = [
  {
    id: 1,
    item: "Zdrowy Sukces",
    extend: true,
  },
  {
    id: 2,
    item: "Ebooki",
    extend: true,
  },
  {
    id: 3,
    item: "Konsultacje",
    href: "/konsultacje",
  },
  {
    id: 4,
    item: "Dla firm",
    href: "/dla-firm",
  },
  {
    id: 5,
    item: "Kontakt",
    href: "/kontakt",
  },
  {
    id: 6,
    item: "Blog",
    href: "/blog",
  },
  {
    id: 7,
    item: "Sklep",
    href: "/sklep",
    extend: true,
  },
]

export const HomeDropDownMenu = [
  {
    id: 101,
    href: "/",
    title: "Przejdź do strony głównej",
    ariaLabel: "Przejdź do strony głównej",
    text: "Strona Główna",
  },
  {
    id: 103,
    href: "/zespol",
    title: "Przejdź do strony zespołu",
    ariaLabel: "Przejdź do strony zespołu",
    text: "Zespół",
  },
  {
    id: 104,
    href: "/#opinie",
    title: "Przejdź do sekcji opinie",
    ariaLabel: "Przejdź do sekcji opinie",
    text: "Opinie",
  },
  {
    id: 105,
    href: "/#newsletter",
    title: "Przejdź do sekcji z zapisem do newslettera",
    ariaLabel: "Przejdź do sekcji z zapisem do newslettera",
    text: "Newsletter",
  },
]

export const ProductItemDropDown = [
  {
    id: 1000,
    title: "Endo e-book",
    href: "/produkt/endo-e-book",
    src: "/images/menu/endo.png",
    bluredDataUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAYAAABLLYUHAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAP0lEQVR4nAE0AMv/ALOtjv/18df/sZt9/wAHAwD/6dW7/4lqTP8AX19H///2u///+9b/AGFWPP81Jw//X1M9/1MTHv3TyopeAAAAAElFTkSuQmCC",
    description: "Endo e-book to specjalistyczny e-book dla kobiet z endometriozą",
    ariaLabel: "Przejdź do strony poświęconej ebookowi o endometriozie",
  },
  {
    id: 1001,
    title: "Helicobacter pylori protokół suplementacyjny",
    href: "/produkt/helicobacter-pylori-protokol-suplementacyjny",
    src: "/images/menu/helicobacter.png",
    bluredDataUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAYAAABLLYUHAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAP0lEQVR4nAE0AMv/AO7es///9s7///XN/wD768D//+3J///92P8AXEYj/2Y8AP/bvYn/ADMaAP8rAAD/y6+D//CEIiRG4o7iAAAAAElFTkSuQmCC",
    description: "Zwalcz Helicobacter pylori w naturalny sposób! Nie działaj w ciemno!",
    ariaLabel: "Przejdź do strony produktu 'Helicobacter pylori protokół suplementacyjny'",
  },
  {
    id: 1002,
    title: "Dieta i kompendium w niepłodności kobiet",
    href: "/produkt/dieta-i-kompendium-w-nieplodnosci-kobiet",
    src: "/images/menu/kompendium-kobiet.png",
    bluredDataUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAYAAABLLYUHAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAP0lEQVR4nAE0AMv/AJNhOf+tinD/9vv//wBgVFP/fVxG/zg4Nf8AemdS/29paP8YFxP/AIUyAP8pAAD/YWxu/81KGVQeQH03AAAAAElFTkSuQmCC",
    description:
      "Dowiedz się o przyczynach niepłodności po stronie kobiet. Wesprzyj płodność dietą.",
    ariaLabel: "Przejdź do strony produktu 'Dieta i kompendium w niepłodności kobiet'",
  },
  {
    id: 1003,
    title: "Dieta i kompendium w niepłodności mężczyzn",
    href: "/produkt/dieta-i-kompendium-w-nieplodnosci-mezczyzn",
    src: "/images/menu/kompendium-mezczyzn.png",
    bluredDataUrl:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAYAAABLLYUHAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAP0lEQVR4nAE0AMv/AP/mx///8M3//86k/wDXv7D///De/4mCfv8AXVdU/6ubkv/CyMv/AAMGDP8jHxz/zYhg/9lZIcc8jCIIAAAAAElFTkSuQmCC",
    description:
      "Dowiedz się o przyczynach niepłodności po stronie mężczyzn. Popraw jakość nasienia dietą.",
    ariaLabel: "Przejdź do strony produktu 'Dieta i kompendium w niepłodności mężczyzn'",
  },
]

export const MobileMenu = [
  {
    id: 10000,
    text: "Strona główna",
    href: "/",
    ariaLabel: "Przejdź do strony głównej",
    title: "Strona główna",
  },
  {
    id: 10009,
    text: "Sklep",
    href: "/sklep",
    ariaLabel: "Przejdź do sklepu zdrowy-sukces",
    title: "Sklep",
    extend: true,
    items: [
      {
        id: 20009,
        href: "/sklep",
        title: "Strona główna sklepu",
        ariaLabel: "Przejdź do sklepu zdrowy-sukces",
        description: "Sprawdź ofertę produktów w naszym sklepie.",
      },
      {
        id: 20010,
        href: "/produkty",
        title: "Wszystkie produkty",
        ariaLabel: "Przejdź do strony ze wszystkimi produktami",
        description:
          "Przeglądaj wszystkie dostępne produkty w naszym sklepie, od diet po ebooki i warsztaty.",
      },
      {
        id: 20011,
        href: "/kategoria/diety",
        title: "Diety",
        ariaLabel: "Przejdź do sklepu z kategorią dieta",
        description: "Znajdź specjalistyczne diety dostosowane do różnych potrzeb zdrowotnych.",
      },
      {
        id: 20012,
        href: "/kategoria/ebooki",
        title: "E-booki",
        ariaLabel: "Przejdź do sklepu z kategorią e-booki",
        description:
          "Odkryj moją kolekcję ebooków, które oferują cenne informacje na temat zdrowia, diety i stylu życia.",
      },
      {
        id: 20013,
        href: "/kategoria/pakiety",
        title: "Pakiety",
        ariaLabel: "Przejdź do sklepu z kategorią pakiety",
        description:
          "Kupuj w pakiecie taniej! Oferuje zestawy produktów, które pomogą Ci zaoszczędzić, jednocześnie spełniając Twoje potrzeby zdrowotne i dietetyczne.",
      },
    ],
  },
  {
    id: 10002,
    text: "Ebooki",
    href: "/kategoria/ebooki",
    extend: true,
    items: [
      {
        id: 20000,
        title: "Endo e-book",
        href: "/produkt/endo-e-book",
        description: "Endo e-book to specjalistyczny e-book dla kobiet z endometriozą",
        ariaLabel: "Przejdź do strony poświęconej ebookowi o endometriozie",
      },
      {
        id: 20001,
        title: "Helicobacter pylori protokół suplementacyjny",
        href: "/produkt/helicobacter-pylori-protokol-suplementacyjny",
        description: "Zwalcz Helicobacter pylori w naturalny sposób! Nie działaj w ciemno!",
        ariaLabel: "Przejdź do strony produktu 'Helicobacter pylori protokół suplementacyjny'",
      },
      {
        id: 20002,
        title: "Dieta i kompendium w niepłodności kobiet",
        href: "/produkt/dieta-i-kompendium-w-nieplodnosci-kobiet",
        description:
          "Dowiedz się o przyczynach niepłodności po stronie kobiet. Wesprzyj płodność dietą.",
        ariaLabel: "Przejdź do strony produktu 'Dieta i kompendium w niepłodności kobiet'",
      },
      {
        id: 20003,
        title: "Dieta i kompendium w niepłodności mężczyzn",
        href: "/produkt/dieta-i-kompendium-w-nieplodnosci-mezczyzn",
        description:
          "Dowiedz się o przyczynach niepłodności po stronie mężczyzn. Popraw jakość nasienia dietą.",
        ariaLabel: "Przejdź do strony produktu 'Dieta i kompendium w niepłodności mężczyzn'",
      },
    ],
  },
  {
    id: 10003,
    text: "Diety",
    href: "/kategoria/diety",
    extend: true,
    items: [
      {
        id: 20004,
        title: "Dieta przeciwpasożytnicza, przeciwgrzybicza, low carb",
        href: "/produkt/dieta-przeciwpasozytnicza-przeciwgrzybicza-low-carb-1800-kcal",
        description:
          "Dieta low carb, bez pszenicy, bez glutenu. Zwalcz pasożyty i przerosty grzybicze.",
        ariaLabel:
          "Przejdź do strony produktu 'Dieta przeciwpasożytnicza, przeciwgrzybicza, low carb'",
      },
      {
        id: 20005,
        title: "Dieta ekonomiczna, domowe smaki z niskim ŁG",
        href: "/produkt/dieta-ekonomiczna-domowe-smaki-z-niskim-lg-1800-kcal",
        description:
          "Ekonomiczna dieta inspirowana domowymi smakami, oparta na produktach o niskim ładunku glikemicznym.",
        ariaLabel: "Przejdź do strony produktu 'Dieta ekonomiczna, domowe smaki z niskim ŁG'",
      },
      {
        id: 20006,
        title: "Dieta lekkostrawna, przeciwzapalna",
        href: "/produkt/dieta-lekkostrawna-przeciwzapalna-1600-kcal",
        description: "Dieta idealna dla osób z problemami trawiennymi.",
        ariaLabel: "Przejdź do strony produktu 'Dieta lekkostrawna, przeciwzapalna'",
      },
      {
        id: 20007,
        title: "Dieta wspierająca płodność kobiet",
        href: "/produkt/dieta-wspierajaca-plodnosc-kobiet",
        description:
          "Dieta zaprojektowana, aby wspierać zdrowie hormonalne i poprawić płodność kobiet.",
        ariaLabel: "Przejdź do strony produktu 'Dieta wspierająca płodność kobiet'",
      },
      {
        id: 20008,
        title: "Dieta wspierająca płodność mężczyzn",
        href: "/produkt/dieta-wspierajaca-plodnosc-mezczyzn",
        description:
          "Zbilansowana dieta mająca na celu poprawę jakości nasienia i wsparcie płodności mężczyzn.",
        ariaLabel: "Przejdź do strony produktu 'Dieta wspierająca płodność mężczyzn'",
      },
    ],
  },
  {
    id: 100034,
    text: "Konsultacje",
    href: "/konsultacje",
    ariaLabel: "Przejdź do strony o konsultacjach",
    title: "Konsultacje",
  },
  {
    id: 10008,
    text: "Blog",
    href: "/blog",
    ariaLabel: "Przejdź do bloga zdrowy-sukces",
    title: "Blog",
  },
  {
    id: 10010,
    text: "Newsletter",
    href: "/#newsletter",
    ariaLabel: "Przejdź do newslettera",
    title: "Newsletter",
  },
  {
    id: 10006,
    text: "Kontakt",
    href: "/kontakt",
    ariaLabel: "Przejdź do kontaktu zdrowy-sukces",
    title: "Kontakt",
  },
  {
    id: 10007,
    text: "Zespół",
    href: "/zespol",
    ariaLabel: "Przejdź do zespołu zdrowy-sukces i poznaj najlepszych specjalistów",
    title: "Zespół",
  },
  {
    id: 10005,
    text: "Dla firm",
    href: "/dla-firm",
    ariaLabel: "Przejdź do strony dla firm",
    title: "Dla firm",
  },
]

export const ShopMenu = [
  {
    id: 99999,
    href: "/sklep",
    title: "Sklep",
    ariaLabel: "Przejdź do sklepu Zdrowy Sukces",
    text: "Sklep Zdrowy-Sukces",
    desc: "Odwiedź sklep, gdzie znajdziesz szeroki wybór produktów, w tym specjalistyczne diety, ebooki i wiele więcej.",
  },
  {
    id: 99998,
    href: "/produkty",
    title: "Wszystkie produkty",
    ariaLabel: "Przejdź do strony ze wszystkimi produktami",
    text: "Wszystkie produkty",
    desc: "Przeglądaj wszystkie dostępne produkty w naszym sklepie, od diet po ebooki i warsztaty.",
  },
  {
    id: 99997,
    href: "/kategoria/diety",
    title: "Diety",
    ariaLabel: "Przejdź do sklepu z kategorią dieta",
    text: "Diety",
    desc: "Znajdź specjalistyczne diety dostosowane do różnych potrzeb zdrowotnych.",
  },
  {
    id: 99996,
    href: "/kategoria/ebooki",
    title: "E-booki",
    ariaLabel: "Przejdź do sklepu z kategorią e-booki",
    text: "E-booki",
    desc: "Odkryj moją kolekcję ebooków, które oferują cenne informacje na temat zdrowia, diety i stylu życia.",
  },
  {
    id: 99995,
    href: "/kategoria/pakiety",
    title: "Pakiety",
    ariaLabel: "Przejdź do sklepu z kategorią pakiety",
    text: "Pakiety",
    desc: "Kupuj w pakiecie taniej! Oferuje zestawy produktów, które pomogą Ci zaoszczędzić, jednocześnie spełniając Twoje potrzeby zdrowotne i dietetyczne.",
  },
]

export const dropdownMenuItemsAdmin = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Admin Panel",
    href: "/admin",
  },
  {
    label: "separator",
    href: "#",
    separator: true,
  },
  {
    label: "Zamówienia",
    href: "/zamowienia",
  },
  {
    label: "Ceny",
    href: "/ceny",
  },
  {
    label: "Użytkownicy",
    href: "/users",
  },
  {
    label: "Konsultacje",
    href: "/konsultacje-szczegoly",
  },
  {
    label: "separator",
    href: "#",
    separator: true,
  },
  {
    label: "Treści",
    href: "/outstatic",
  },
  {
    label: "Baner",
    href: "/settings/banner",
  },
  {
    label: "Promocje",
    href: "/settings/ustawienia-promocji",
  },
  {
    label: "separator",
    href: "#",
    separator: true,
  },
]

export const dropdownMenuItemsUser = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "separator",
    href: "#",
    separator: true,
  },
  {
    label: "Ustawienia",
    href: "/ustawienia",
  },
  {
    label: "separator",
    href: "#",
    separator: true,
  },
]
