"use client"

import { useEffect, useState } from "react"
import { saveCookie } from "@/actions/cookies"
import { CookiesSchema } from "@/utils/validations/cookies"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { type z } from "zod"

import { useCookieBanner } from "@/hooks/useCookieBanner"
import { Modal, ModalBody, ModalContent, ModalTrigger } from "@/components/ui/animated-modal"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { CustomLink } from "@/components/ui/link"

import { BannerComponent } from "./Banner"
import { CookieFields } from "./CookieFields"

export type FormFieldType = {
  name: keyof z.infer<typeof CookiesSchema> // name must match the keys from CookiesSchema
  label: string
  description: string
  isDisabled: boolean
}

type Banner = {
  id: number
  href: string
  text: string
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export const CookiesProvider = () => {
  const { isClient, hasCookie, showBanner, setReload, setHasCookie, setShowBanner } =
    useCookieBanner()
  const [data, setData] = useState<Banner | null>(null) // Użyj Banner lub null
  const [error, setError] = useState<string | null>(null) // Typ błędu

  useEffect(() => {
    async function fetchBanner() {
      try {
        const res = await fetch("/api/banner", { next: { revalidate: 2 } })
        if (!res.ok) {
          throw new Error("Failed to fetch banner data")
        }
        const bannerData: Banner = await res.json()
        setData(bannerData)
      } catch (error: unknown) {
        console.error("Error fetching banner:", error)
        setError(error instanceof Error ? error.message : String(error))
      }
    }

    fetchBanner()
  }, [])

  const form = useForm<z.infer<typeof CookiesSchema>>({
    resolver: zodResolver(CookiesSchema),
    defaultValues: {
      truly_necessary: true,
      functional: true,
      analytical: true,
      advertising: true,
      personalizing_content: true,
      user_data: true,
      personalizing_ads: true,
    },
  })

  const onSubmit = async (data: z.infer<typeof CookiesSchema>) => {
    await saveCookie(data, "preferences")
    setHasCookie(true)
    setShowBanner(true)
    setReload(true)
  }

  const acceptAllCookies = async () => {
    const allTrueValues = Object.keys(CookiesSchema.shape).reduce<
      Record<keyof z.infer<typeof CookiesSchema>, boolean>
    >(
      (acc, key) => {
        acc[key as keyof z.infer<typeof CookiesSchema>] = true
        return acc
      },
      {
        truly_necessary: true,
        functional: true,
        analytical: true,
        advertising: true,
        personalizing_content: true,
        user_data: true,
        personalizing_ads: true,
      }
    )

    await onSubmit(allTrueValues)
  }

  const formFields: FormFieldType[] = [
    {
      name: "truly_necessary",
      label: "Naprawdę niezbędne",
      description:
        "Plikie te są niezbędne i umożliwiają odwiedzającym przeglądanie witryny i korzystanie z jej funkcji.",
      isDisabled: true,
    },
    {
      name: "functional",
      label: "Funkcjonalne",
      description:
        "Umożliwia przechowywanie danych, które obsługują funkcje witryny lub aplikacji, np. ustawień języka.",
      isDisabled: true,
    },
    {
      name: "analytical",
      label: "Analityczne",
      description: "Umożliwia przechowywanie danych (np. plików cookie) dotyczących statystyk.",
      isDisabled: false,
    },
    {
      name: "advertising",
      label: "Reklamowe",
      description: "Umożliwia przechowywanie danych związanych z reklamami.",
      isDisabled: false,
    },
    {
      name: "personalizing_content",
      label: "Personalizujące treść",
      description: "Dostosowanie treści i reklam na stronie do Twoich potrzeb.",
      isDisabled: false,
    },
    {
      name: "user_data",
      label: "Dane użytkownika",
      description: "Zbieranie i analizowanie informacji dotyczących Twojej aktywności.",
      isDisabled: false,
    },
    {
      name: "personalizing_ads",
      label: "Personalizujące reklamy",
      description: "Dostosowanie reklam na podstawie Twojego zachowania online.",
      isDisabled: false,
    },
  ]

  if (!isClient || hasCookie === null) return null
  if ((showBanner && error) || (showBanner && data == null) || (showBanner && data == undefined))
    return null
  return (
    <div className="bg-primary-500 fixed inset-x-0 top-[69px] z-20" id="view-banner">
      {showBanner && data !== undefined && data !== null && (
        <BannerComponent href={data.href} text={data.text} />
      )}
      {!hasCookie && (
        <div className="basic-padding mx-auto max-w-screen-2xl py-4 sm:py-2">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <section className="text-sm">
              <p className="group relative inline font-semibold">Zgoda na ciasteczka&nbsp;</p>
              <p className="group relative inline">
                Korzystając ze strony zgadzasz się na użycie ciasteczek.&nbsp;
              </p>
              <CustomLink
                className="underline opacity-80"
                href="/polityka-prywatnosci"
                rel={"noreferrer noopener"}
                ariaLabel={"Przejdź do polityki-prywatnosci"}
                title={"Polityka prywatności"}
                target={"_blank"}
              >
                Dowiedz się&nbsp;więcej
              </CustomLink>
            </section>

            <div className="flex gap-3">
              <Button variant={"outline"} size={"sm"} onClick={acceptAllCookies}>
                Akceptuj
              </Button>
              <Modal>
                <ModalTrigger className="cursor-pointer">Dostosuj</ModalTrigger>
                <ModalBody>
                  <ModalContent>
                    <section className="mb-8">
                      <h3 className="text-lg font-medium">Ustawienia ciasteczek</h3>
                    </section>
                    <Form {...form}>
                      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
                        <CookieFields control={form.control} formFields={formFields} />
                        <Button type="submit">Zapisz preferencje</Button>
                      </form>
                    </Form>
                  </ModalContent>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
