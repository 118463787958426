"use client"

import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ReactNode,
} from "react"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"

import { Button } from "./button"

interface ModalContextType {
  open: boolean
  setOpen: (open: boolean) => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false)

  return <ModalContext.Provider value={{ open, setOpen }}>{children}</ModalContext.Provider>
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}

export function Modal({ children }: { children: ReactNode }) {
  return <ModalProvider>{children}</ModalProvider>
}

export const ModalTrigger = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  const { setOpen } = useModal()
  return (
    <Button variant={"outline"} size={"sm"} className={className} onClick={() => setOpen(true)}>
      {children}
    </Button>
  )
}

export const ModalTriggerForm = forwardRef(
  (
    {
      children,
      className,
      size,
    }: {
      children: ReactNode
      className?: string
      size: "default" | "sm" | "lg" | "icon"
    },
    ref
  ) => {
    const { setOpen } = useModal()

    useImperativeHandle(ref, () => ({
      openModal: () => setOpen(true),
      closeModal: () => setOpen(false),
    }))

    return (
      <Button
        variant={"outline"}
        size={size}
        type={"button"}
        className={className}
        aria-label={"Edytuj datę"}
        aria-labelledby={"Edytuj datę"}
        title={"Edytuj datę"}
        role="button"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setOpen(true)
        }}
      >
        {children}
      </Button>
    )
  }
)

ModalTriggerForm.displayName = "ModalTriggerForm"

const CloseIconForm = () => {
  const { setOpen } = useModal()

  return (
    <button
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        setOpen(false)
      }}
      className="group absolute right-4 top-4"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="size-4 text-black transition duration-200 group-hover:rotate-3 group-hover:scale-125 dark:text-white"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M18 6l-12 12" />
        <path d="M6 6l12 12" />
      </svg>
    </button>
  )
}

export const ModalBody = ({
  children,
  className,
  form = false,
}: {
  children: ReactNode
  className?: string
  form?: boolean
}) => {
  const { open } = useModal()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [open])

  const modalRef = useRef<HTMLDivElement>(null)
  const { setOpen } = useModal()
  useOutsideClick(modalRef, () => {
    if (!form) {
      setOpen(false)
    }
  })

  useEffect(() => {
    if (form) {
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape" && form) {
          setOpen(false)
        }
      }

      if (open) {
        document.addEventListener("keydown", handleEscape)
      }

      return () => {
        document.removeEventListener("keydown", handleEscape)
      }
    }
  }, [form, open, setOpen])

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            backdropFilter: "blur(10px)",
          }}
          exit={{
            opacity: 0,
            backdropFilter: "blur(0px)",
          }}
          className="fixed inset-0 z-50 !ml-0 flex size-full items-center justify-center [perspective:800px] [transform-style:preserve-3d]"
        >
          <Overlay />

          <motion.div
            ref={modalRef}
            className={cn(
              "relative z-50 mx-4 flex min-h-[50%] flex-1 flex-col overflow-hidden rounded-2xl border border-transparent bg-white md:max-w-[70%] lg:max-w-[40%] dark:border-neutral-800 dark:bg-neutral-950",
              className
            )}
            initial={{
              opacity: 0,
              scale: 0.5,
              rotateX: 40,
              y: 40,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              rotateX: 0,
              y: 0,
            }}
            exit={{
              opacity: 0,
              scale: 0.8,
              rotateX: 10,
            }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 15,
            }}
          >
            {form ? <CloseIconForm /> : <CloseIcon />}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export const ModalContent = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return <div className={cn("flex flex-1 flex-col p-8 md:p-10", className)}>{children}</div>
}

export const ModalFooter = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div className={cn("flex justify-end bg-gray-100 p-4 dark:bg-neutral-900", className)}>
      {children}
    </div>
  )
}

const Overlay = ({ className }: { className?: string }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        backdropFilter: "blur(10px)",
      }}
      exit={{
        opacity: 0,
        backdropFilter: "blur(0px)",
      }}
      className={`bg-opacity/50 fixed inset-0 z-50 size-full bg-black ${className && className}`}
    ></motion.div>
  )
}

const CloseIcon = () => {
  const { setOpen } = useModal()
  return (
    <button onClick={() => setOpen(false)} className="group absolute right-4 top-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="size-4 text-black transition duration-200 group-hover:rotate-3 group-hover:scale-125 dark:text-white"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M18 6l-12 12" />
        <path d="M6 6l12 12" />
      </svg>
    </button>
  )
}

// Hook to detect clicks outside of a component.
// Add it in a separate file, I've added here for simplicity
export const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement | null>,
  callback: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      // DO NOTHING if the element being clicked is the target element or their children
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }
      callback(event)
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      // Call the callback if the "Escape" key is pressed
      if (event.key === "Escape") {
        callback(event)
      }
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)
    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [ref, callback])
}
