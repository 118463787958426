"use client"

import { type CookiesSchema } from "@/utils/validations/cookies"
import { type Control } from "react-hook-form"
import { type z } from "zod"

import { FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { Switch } from "@/components/ui/switch"

import { ScrollArea } from "../ui/scroll-area"
import { type FormFieldType } from "./Cookies"

interface CookieFieldsProps {
  control: Control<z.infer<typeof CookiesSchema>>
  formFields: FormFieldType[]
}
export const CookieFields = ({ control, formFields }: CookieFieldsProps) => (
  <ScrollArea className="h-72 w-full rounded-md pr-4">
    {formFields.map((field) => (
      <FormField
        key={field.name}
        control={control}
        name={field.name}
        render={({ field: formField }) => (
          <FormItem className="border-input mb-2 mr-4 flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <FormLabel className="text-base">{field.label}</FormLabel>
              <FormDescription className="text-popover-foreground">
                {field.description}
              </FormDescription>
            </div>
            <FormControl>
              <Switch
                checked={formField.value}
                onCheckedChange={formField.onChange}
                disabled={field.isDisabled}
                aria-readonly={field.isDisabled}
              />
            </FormControl>
          </FormItem>
        )}
      />
    ))}
  </ScrollArea>
)
