"use client"

import { useEffect, useState } from "react"
import { usePathname } from "next/navigation"
import { RedirectToPath } from "@/actions/redirect"
import Cookies from "js-cookie"

export const useCookieBanner = () => {
  const [isClient, setIsClient] = useState(false)
  const [hasCookie, setHasCookie] = useState<boolean | null>(null)
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const pathname = usePathname() // Zmienna przechowująca aktualną ścieżkę

  useEffect(() => {
    setIsClient(true)
    const preferencesCookie = Cookies.get("preferences")
    const cookieExists = Boolean(preferencesCookie)
    setHasCookie(cookieExists)
    setShowBanner(cookieExists)
    if (reload) RedirectToPath(pathname || "/")
  }, [reload, pathname])

  return {
    isClient,
    hasCookie,
    showBanner,
    setReload,
    setHasCookie,
    setShowBanner,
  }
}
