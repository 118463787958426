"use client"

import React, { useState } from "react"
import { HomeDropDownMenu, MainMenu, MobileMenu, ProductItemDropDown, ShopMenu } from "@/data/menu"
import { cn } from "@/utils/cn"
import { getSubNav } from "@/utils/menu"
import { AlignJustify } from "lucide-react"
import { type User } from "next-auth"
import { signOut, useSession } from "next-auth/react"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Button } from "@/components/ui/button"
import { CustomLink } from "@/components/ui/link"
import { ComponentSearch } from "@/components/ComponentSearch"

import { ThemeToggle } from "./ThemeSwitch"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu"
import { Logo } from "./ui/logo"
import { HoveredLink, Menu, MenuItem, MenuItemLink, ProductItem } from "./ui/navbar-menu"
import SearchButton from "./ui/search-button"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "./ui/sheet"
import { Skeleton } from "./ui/skeleton"
import { UserAvatar } from "./UserAvatar"

interface MyUser extends User {
  role: string
}

export function NavbarMenu() {
  return (
    <div className="relative flex w-full items-center justify-center">
      <ComponentSearch>
        <Navbar className="top-0 w-full" />
      </ComponentSearch>
    </div>
  )
}

export const UserInterface = () => {
  const { status, data: session } = useSession()
  const user: Pick<MyUser, "name" | "image" | "email" | "role"> | undefined = session?.user
  if (status == "loading" && user == undefined) {
    return (
      <div className="flex size-auto items-center justify-center">
        <Skeleton className="size-7 rounded align-middle" />
      </div>
    )
  }
  if (user == undefined) {
    return (
      <Button asChild variant={"default"} className="mx-2 min-[830px]:mr-0">
        <CustomLink
          href={"/logowanie"}
          ariaLabel={"Przejdź do strony logowania"}
          rel="noreferrer noopener"
          target="_self"
          title="Logowanie"
          text="Logowanie"
        />
      </Button>
    )
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        aria-label={"Rozwiń menu zalogowanego użytkownika"}
        aria-labelledby={"Rozwiń menu zalogowanego użytkownika"}
        title={"Rozwiń menu zalogowanego użytkownika"}
        role="button"
        className="hover:bg-accent hover:text-accent-foreground focus-visible:ring-ring inline-flex size-9 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50"
      >
        <UserAvatar
          user={{ name: user.name || null, image: user.image || null }}
          className="size-5"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56">
        <div className="flex items-center justify-start gap-2 p-2">
          <div className="flex flex-col space-y-1 leading-none">
            {user.name && <p className="font-medium">{user.name}</p>}
            {user.email && (
              <p className="text-foreground-400 w-[200px] truncate text-sm">{user.email}</p>
            )}
          </div>
        </div>
        <DropdownMenuSeparator />
        {getSubNav(user.role).map((item, index) => {
          if (item.separator) {
            return <DropdownMenuSeparator key={item.label + index} />
          }
          return (
            <DropdownMenuItem
              key={item.label}
              className="group/menu hover:text-primary-500 cursor-pointer p-0 transition duration-200 hover:bg-gray-400/5"
            >
              <CustomLink
                href={item.href}
                rel={"noreferrer noopener"}
                ariaLabel={`Przejdź do ${item.label}`}
                title={item.label}
                target={"_self"}
                className="size-full px-2 py-1.5 transition-all duration-150"
              >
                {item.label}
              </CustomLink>
            </DropdownMenuItem>
          )
        })}
        <DropdownMenuItem
          className="hover:text-primary-500 cursor-pointer transition-all duration-150 hover:bg-gray-400/5"
          onSelect={(event) => {
            event.preventDefault()
            signOut({
              callbackUrl: `${window.location.origin}/logowanie`,
            })
          }}
        >
          Wyloguj
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

function Navbar({ className }: { className?: string }) {
  const [active, setActive] = useState<string | null>(null)
  const [open, setOpen] = useState(false)

  return (
    <div
      className={cn(
        "fixed inset-x-0 z-50 mx-auto",
        "flex items-center justify-center",
        "bg-background/95 supports-[backdrop-filter]:bg-background/60 backdrop-blur backdrop-saturate-150",
        "border-border/40 border-b dark:border-white/[0.2]",
        "text-black dark:text-white",
        className
      )}
    >
      <Menu setActive={setActive}>
        <section className="flex justify-start lg:basis-40">
          <Button asChild variant="none" className="pl-0">
            <CustomLink
              href={"/"}
              ariaLabel={"Przejdź do strony głównej"}
              rel="noreferrer noopener"
              target="_self"
              title="Strona Główna"
            >
              <Logo width={32} height={32} />
            </CustomLink>
          </Button>
        </section>
        <section className="hidden w-full items-center justify-center space-x-4 text-sm min-[830px]:flex min-[890px]:text-base">
          {MainMenu.map((item) => {
            if (item.extend && item.item == "Zdrowy Sukces") {
              return (
                <MenuItem
                  key={item.id}
                  setActive={setActive}
                  active={active}
                  item="Zdrowy Sukces"
                  href="/"
                >
                  <div className="flex flex-col space-y-4 text-sm">
                    {HomeDropDownMenu.map((item) => (
                      <HoveredLink
                        key={item.id}
                        href={item.href}
                        title={item.title}
                        ariaLabel={item.ariaLabel}
                      >
                        {item.text}
                      </HoveredLink>
                    ))}
                  </div>
                </MenuItem>
              )
            }
            if (item.extend && item.item == "Ebooki") {
              return (
                <MenuItem
                  key={item.id}
                  setActive={setActive}
                  active={active}
                  item="Ebooki"
                  href="kategoria/ebooki"
                >
                  <div className="grid grid-cols-1 gap-x-2 gap-y-8 p-4 text-sm lg:grid-cols-2">
                    {ProductItemDropDown.map((item) => (
                      <ProductItem
                        key={item.id}
                        title={item.title}
                        href={item.href}
                        src={item.src}
                        bluredDataUrl={item.bluredDataUrl}
                        description={item.description}
                        ariaLabel={item.ariaLabel}
                      />
                    ))}
                  </div>
                </MenuItem>
              )
            }

            if (item.extend && item.item == "Sklep") {
              return (
                <MenuItem
                  key={item.id}
                  setActive={setActive}
                  active={active}
                  item="Sklep"
                  className="grid list-none gap-1 md:w-[400px] lg:w-[600px] lg:grid-cols-[.75fr_1fr] lg:gap-3 xl:w-[700px]"
                  href={"sklep"}
                >
                  {ShopMenu.map((el, idx) => {
                    if (idx == 0) {
                      return (
                        <CustomLink
                          key={el.id}
                          className="group row-span-4"
                          href={el.href}
                          rel={"noreferrer noopener"}
                          ariaLabel={el.ariaLabel}
                          title={el.title}
                          target={"_self"}
                        >
                          <div className="relative flex size-full select-none flex-col justify-end rounded-md bg-[url('/images/store/bg-menu.png')] bg-cover bg-scroll bg-center bg-no-repeat p-4 no-underline outline-none focus:shadow-md lg:p-6 dark:bg-[url('/images/store/bg-menu-dark.png')]">
                            <div className="group-hover:text-primary-500 mb-2 mt-4 text-lg font-semibold transition-all">
                              {el.text}
                            </div>
                            <p className="text-muted-foreground text-sm leading-tight">{el.desc}</p>
                          </div>
                        </CustomLink>
                      )
                    }
                    return (
                      <CustomLink
                        key={el.id}
                        href={el.href}
                        rel={"noreferrer noopener"}
                        ariaLabel={el.ariaLabel}
                        title={el.title}
                        target={"_self"}
                        className="hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors"
                      >
                        <div className="group-hover:text-primary-500 text-base font-semibold leading-none transition-all">
                          {el.text}
                        </div>
                        <p className="text-muted-foreground line-clamp-3 text-sm leading-snug">
                          {el.desc}
                        </p>
                      </CustomLink>
                    )
                  })}
                </MenuItem>
              )
            }
            return (
              <MenuItemLink
                key={item.id}
                setActive={setActive}
                item={item.item}
                href={item.href ?? ""}
              />
            )
          })}
        </section>
        <section className="hidden min-[830px]:flex">
          <ThemeToggle />
          <SearchButton />
          <UserInterface />
        </section>

        <div className="flex gap-1 min-[830px]:hidden">
          <Sheet open={open} onOpenChange={setOpen}>
            <ThemeToggle />
            <SearchButton />
            <UserInterface />
            <SheetTrigger
              aria-label="Menu Open"
              aria-labelledby="Menu Open"
              title="Menu Open"
              className="focus-visible:ring-ring rounded-md focus-visible:outline-none focus-visible:ring-1"
            >
              <AlignJustify />
            </SheetTrigger>
            <SheetContent className="no-scrollbar overflow-y-auto">
              <SheetHeader>
                <SheetTitle className="text-left">Menu - Zdrowy Sukces</SheetTitle>
              </SheetHeader>
              <div className="mt-10 flex w-full flex-col items-start space-y-4 pb-20 text-left text-lg">
                {MobileMenu.map((item) => {
                  if (item.extend) {
                    return (
                      <Accordion key={item.id} type="single" collapsible className="w-full">
                        <AccordionItem value="Ebooki">
                          <AccordionTrigger className="hover:text-primary-500">
                            {item.text}
                          </AccordionTrigger>
                          <AccordionContent className="ml-3 flex flex-col gap-2 pt-3">
                            {item.items.map((item) => {
                              return (
                                <CustomLink
                                  key={item.id}
                                  href={item.href ?? "/"}
                                  ariaLabel={item.ariaLabel ?? ""}
                                  rel="noreferrer noopener"
                                  target="_self"
                                  title={item.title ?? ""}
                                  className="hover:text-primary-500 mr-1 p-1 last-of-type:mb-1"
                                  onClick={() => setOpen(false)}
                                >
                                  {item.title}
                                </CustomLink>
                              )
                            })}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    )
                  }

                  return (
                    <CustomLink
                      key={item.id}
                      href={item.href ?? "/"}
                      ariaLabel={item.ariaLabel ?? ""}
                      rel="noreferrer noopener"
                      target="_self"
                      title={item.title ?? ""}
                      text={item.text ?? ""}
                      className="hover:text-primary-500"
                      onClick={() => setOpen(false)}
                    />
                  )
                })}
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </Menu>
    </div>
  )
}
