"use client"

import { type ReactNode } from "react"
import { useRouter } from "next/navigation"
import { KBarSearchProvider } from "pliny/search/KBar.js"

type JSONObject = {
  id: string
  name: string
  keywords: string
  section: "Posty" | "Produkty" | "Tagi" | "Menu"
  subtitle: string
  slug: string
}

export const ComponentSearch = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  return (
    <KBarSearchProvider
      kbarConfig={{
        searchDocumentsPath: "/search/search.json",
        defaultActions: [
          {
            id: "homepage",
            name: "Strona Główna",
            shortcut: ["s"],
            keywords: "Strona Główna",
            section: "Menu",
            perform: () => router.push("/"),
          },
          {
            id: "blog",
            name: "Blog",
            shortcut: ["b"],
            keywords: "Blog",
            section: "Menu",
            perform: () => router.push("/blog"),
          },
          {
            id: "zespol",
            name: "Zespół",
            shortcut: ["z"],
            keywords: "Zespół",
            section: "Menu",
            perform: () => router.push("/zespol"),
          },
          {
            id: "kontakt",
            name: "Kontakt",
            keywords: "Kontakt",
            shortcut: ["c"],
            section: "Menu",
            perform: () => router.push("/kontakt"),
          },
          {
            id: "sklep",
            name: "Sklep",
            shortcut: ["s"],
            keywords: "Sklep, diety, ebooki, konsultacje",
            // subtitle: "Sprawdź moje produkty i już dziś zacznij wprowadzać zdrowe nawyki do swojego życia",
            section: "Menu",
            perform: () => router.push("/sklep"),
          },
          {
            id: "konsultacje",
            name: "Konsultacje",
            shortcut: ["k"],
            keywords: "Konsultacje",
            // subtitle: "Zapisz się na konsultacje!",
            section: "Menu",
            perform: () => router.push("/#konsultacje"),
          },
          {
            id: "tagi",
            name: "Tagi",
            shortcut: ["t"],
            keywords: "Tagi",
            // subtitle: "Zapisz się na konsultacje!",
            section: "Menu",
            perform: () => router.push("/tagi"),
          },
        ],
        onSearchDocumentsLoad(json: JSONObject[]) {
          return json.map((item: JSONObject) => ({
            id: item.slug,
            name: item.name,
            keywords: item.keywords,
            section: item.section,
            subtitle: item.subtitle,
            perform: () => router.push(item.slug),
          }))
        },
      }}
    >
      {children}
    </KBarSearchProvider>
  )
}
