import { siteConfig } from "@/data/site"
import { Search } from "lucide-react"
import { AlgoliaButton } from "pliny/search/AlgoliaButton.js"
import { KBarButton } from "pliny/search/KBarButton.js"

const SearchButton = () => {
  if (
    siteConfig.search &&
    (siteConfig.search.provider === "algolia" || siteConfig.search.provider === "kbar")
  ) {
    const SearchButtonWrapper =
      siteConfig.search.provider === "algolia" ? AlgoliaButton : KBarButton

    return (
      <SearchButtonWrapper
        aria-label="Search"
        role="button"
        aria-labelledby="Search"
        title="Search"
        className="hover:bg-accent hover:text-accent-foreground focus-visible:ring-ring inline-flex size-9 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50"
      >
        <Search className="size-5" />
        <span className="sr-only">Wyszukaj</span>
      </SearchButtonWrapper>
    )
  }
}

export default SearchButton
